import { minStartDate } from 'constants/index';
import { Chart } from 'types/Chart';
import ValveStatus from 'components/ValveStatus';

const charts: Record<string, Chart> = {
  chart2: {
    title: 'Average Hourly Water Usage',
    subtitle: 'Day',
    units: 'gallons',
    src: 'https://garnet.rd.klika-tech.com/grafana/d-solo/ads43bahcqc5ce/garnet?orgId=1&from=now/d%2B1h&to=now&refresh=5m&theme=light&panelId=11',
    scenarioSrc: 'https://garnet.rd.klika-tech.com/grafana/d-solo/ads43bahcqc5ce/garnet?orgId=1&from=now-24h&to=now&refresh=1s&theme=light&panelId=8',
    timeRange: 24 * 60 * 60 * 1000,
    timeTo: { h: 23, m: 59, s: 59 },
    minStartDate: minStartDate,
  },
  chart1: {
    title: 'Daily Total Water Usage',
    subtitle: 'Month',
    units: 'gallons',
    src: 'https://garnet.rd.klika-tech.com/grafana/d-solo/ads43bahcqc5ce/garnet?orgId=1&from=now-20d&to=now&refresh=5m&theme=light&panelId=13',
    // scenarioSrc: 'https://garnet.rd.klika-tech.com/grafana/d-solo/ads43bahcqc5ce/garnet?orgId=1&from=now-24h&to=now&refresh=1s&theme=light&panelId=9',
    timeRange: 20 * 24 * 60 * 60 * 1000,
    minStartDate: minStartDate,
  },
  chart4: {
    title: 'Valve Status Over Time',
    src: 'https://garnet.rd.klika-tech.com/grafana/d-solo/ads43bahcqc5ce/garnet?orgId=1&from=now/d%2B1h&to=now&refresh=5m&theme=light&panelId=12',
    scenarioSrc: 'https://garnet.rd.klika-tech.com/grafana/d-solo/ads43bahcqc5ce/garnet?orgId=1&from=now-24h&to=now&refresh=1s&theme=light&panelId=10',

    timeRange: 12 * 60 * 60 * 1000,
    minStartDate: minStartDate,
    addon: {
      componentGetter: props => <ValveStatus {...props} />,
      // TODO consider to move here necessary settings for ValveStatus component, fetch params.
      // Needed additional investigation how to simplify a request body
      props: {/*refresh: 30000*/ },
    },
  },
  chart3: {
    title: 'Cumulative usage',
    subtitle: 'Day',
    units: 'gallons',
    // src: 'https://garnet.rd.klika-tech.com/grafana/d-solo/ads43bahcqc5ce/garnet?orgId=1&from=now-24h&to=now&refresh=5m&theme=light&panelId=6',
    src: 'https://garnet.rd.klika-tech.com/grafana/d-solo/ads43bahcqc5ce/garnet?orgId=1&from=now/d%2B1h&to=now&refresh=5m&theme=light&panelId=6',

    timeRange: 24 * 60 * 60 * 1000,
    timeTo: { h: 23, m: 59, s: 59 },
    minStartDate: minStartDate,
  },
};

export default charts;
