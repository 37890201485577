import React from 'react';

const Loader = () => {
  return (
    <span className="spinner-wrapper">
      <span className="spinner"></span>
    </span>
  );
}

export default Loader;