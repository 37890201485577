import React from 'react';
import { TypeScenario } from 'types/common';
import Loader from 'components/Loader';

interface Props {
  type: TypeScenario;
  onClick: (type: TypeScenario) => void,
  disabled?: boolean,
  isRunning?:boolean,
}

const ScenarioButton = ({type, onClick, disabled, isRunning}: Props) => {
  const className = `main__scenario-button_${type}`;
  const handleClick = () => {
    onClick(type);
  };

  return (
    <button
      className={`main__scenario-button ${className}`}
      title={type.toUpperCase()}
      onClick={handleClick}
      disabled={disabled}
    >
      {isRunning ? <Loader /> : null}
    </button>
  );
};

export default ScenarioButton;