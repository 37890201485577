import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Chart } from 'types/Chart';

interface Props {
  date: Date,
  chart: Chart,
  isMasterLoaded: boolean,
  // refresh: number,
  [p: string]: any, // temporary due to extra props from IframeChart
}

// there is no "refresh" logic. If it requires such logic here is probable description:
// If user did not select the date the valve status should be refreshed if user selected a particular date then refresh logic should be disabled
const ValveStatus = ({ date, chart, isMasterLoaded, isAlarm }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  const query = `
  WITH 
  target_dates AS (
    SELECT 
      date_add('month', -(month($__timeFrom) - 10), $__timeFrom) as original_start_date,
      date_add('month', -(month($__timeTo) - 10), $__timeTo) as original_end_date,
      $__timeFrom as display_start_date,
      $__timeTo as display_date
  ),
  valve_data AS (
    SELECT 
      parse_datetime(valvestatus.createdat, 'yyyy-MM-dd''T''HH:mm:ss.SSSSSS''Z') as timestamp,
      CASE WHEN valvestatus.value = 'open' THEN 1 ELSE 0 END as valveStatus
    FROM garnetdb.valve
    WHERE 
      parse_datetime(valvestatus.createdat, 'yyyy-MM-dd''T''HH:mm:ss.SSSSSS''Z') > 
        (SELECT original_start_date FROM target_dates)
      AND
      parse_datetime(valvestatus.createdat, 'yyyy-MM-dd''T''HH:mm:ss.SSSSSS''Z') <= 
        (SELECT original_end_date FROM target_dates)
  )
  SELECT
    date_add('month', 
      month($__timeTo) - month(original_end_date) + 
      12 * (year($__timeTo) - year(original_end_date)), 
      timestamp
    ) as time,
    valveStatus
  FROM valve_data
  CROSS JOIN target_dates
  ORDER BY time;
  `

  const fetchData = useCallback(({ from, to }: { from: number, to: number }) => {
    const endpoint = '/api/ds/query';
    const headers = {
      'Content-Type': 'application/json',
    };
    const body = {
      "queries": [
        {
          "connectionArgs": {
            "catalog": "__default",
            "database": "garnetdb",
            "region": "us-west-1",
            "resultReuseEnabled": false,
            "resultReuseMaxAgeInMinutes": 60
          },
          "datasource": {
            // TODO the most likely the datasource type must be changed in case of a scenario
            "type": "grafana-athena-datasource",
            "uid": "advzn0xneuhvkc"
          },
          "format": 1,
          // TODO the most likely the query must be changed in case of a scenario
          "rawSQL": query,
          "refId": "A",
          "intervalMs": 60000,
          "maxDataPoints": 500,
          // TODO the most likely the datasource must be changed in case of a scenario
          "datasourceId": 1
        }
      ],
      "from": from + '',
      "to": to + ''
    };

    return fetch(
      // TODO:env var config: use env variables when ci-cd proccess is configured
      // process.env.REACT_APP_BASE_API + endpoint,
      '/grafana' + endpoint,
      { method: "POST", headers, body: JSON.stringify(body) })
      .then(response => response.json());
  }, []);

  useEffect(() => {
    if (isAlarm === false || isAlarm === true) { // is considered as force handling
      setIsLoading(false);
      return;
    }
    const from = (date.valueOf() - chart.timeRange);
    const to = date.valueOf();

    setIsLoading(true);
    fetchData({ from, to })
      .then(d => {
        setIsLoading(false);

        if (
          !d.results ||
          !d.results.A ||
          !d.results.A.frames ||
          !d.results.A.frames[0] ||
          !d.results.A.frames[0].data ||
          !d.results.A.frames[0].data.values ||
          !d.results.A.frames[0].data.values[0] ||
          !d.results.A.frames[0].data.values[1] ||
          !Array.isArray(d.results.A.frames[0].data.values[0]) ||
          !Array.isArray(d.results.A.frames[0].data.values[1])
        ) {
          setHasError(true);
          return;
        }

        const valuesX = d.results.A.frames[0].data.values[0];
        const valuesY = d.results.A.frames[0].data.values[1];

        setHasError(false);

        if (!valuesX.length) {
          const lastValveStatus = valuesY.slice(-1)[0];
          setIsOpen(!!lastValveStatus);
          return;
        }

        if (to < valuesX[0]) {
          setHasError(true);
        } else if (to > valuesX[valuesX.length - 1]) {
          // use the last value
          const lastValveStatus = valuesY.slice(-1)[0];
          setIsOpen(!!lastValveStatus);
        } else {
          let indexResult = null;
          let index = 0;

          while (index < valuesX.length) {
            if (valuesX[index] < to) {
              index++;
              continue;
            }
            indexResult = index;
            break;
          }

          if (indexResult === null) {
            setHasError(true);
            console.log('No corresponding value for requested date');
            return;
          }

          const valveStatus = valuesY[indexResult - 1];
          // console.log('valveStatus: ', valveStatus);
          setIsOpen(!!valveStatus);
        }
      })
      .catch(err => {
        setHasError(true);
        console.log(err);
      });
  }, [date, chart, fetchData, isAlarm]);

  const isOpenClass = useMemo(() => {
    if (isAlarm === false || isAlarm === true)
      return isAlarm ? '' : 'valve-indicator__open';

    return isOpen ? 'valve-indicator__open' : '';
  }, [isOpen, isAlarm]);

  return (
    <div className={`valve-indicator ${isOpenClass} ${isLoading || hasError || !isMasterLoaded ? 'valve-indicator__hidden' : ''}`}>
      {/* add or remove valve-indicator__open class to animate the valve*/}
      <div className="valve-indicator__inner">
        <div className="pipe pipe__close"></div>
        <div className="pipe pipe__open"></div>
        <div className="valve valve__close"></div>
        <div className="valve valve__open"></div>
      </div>
    </div>
  );
};

export default ValveStatus;