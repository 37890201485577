import React, { useEffect, useMemo, useState } from 'react';
import { ModalOptions, TypeScenario } from 'types/common';
import { ReactComponent as CriticalIcon } from 'assets/img/critical-notif.svg';
import { ReactComponent as FloodHouse } from 'assets/img/flood-house.svg';
import prompts from 'assets/prompts.json'
import { getDate } from 'utils';
import { useBedrock } from '../BedrockProvider';
import { InvokeModelWithResponseStreamCommand } from "@aws-sdk/client-bedrock-runtime";
import ReactMarkdown from 'react-markdown';

const titleMapper: Record<TypeScenario, string> = {
  critical: 'Warning!',
  warn: 'Warning!',
  info: 'Notification',
};

const textMapper: Record<TypeScenario, string> = {
  critical: 'critical leakage',
  warn: 'slow leakage',
  info: 'lack of activity',
};

const promptMapper: Record<TypeScenario, string> = {
  critical: prompts.critical,
  warn: prompts.slow,
  info: prompts.absence,
};

const Modal = ({ type, pointDate, close }: ModalOptions) => {
  const { getClient } = useBedrock();
  const [recommendation, setRecommendation] = useState('');
  const text = textMapper[type];

  useEffect(() => {
    const generateRecommendation = async () => {
      try {
        setRecommendation('');

        const client = await getClient();

        const input = {
          modelId: 'anthropic.claude-3-haiku-20240307-v1:0',
          contentType: 'application/json',
          accept: 'application/json',
          body: JSON.stringify({
            anthropic_version: "bedrock-2023-05-31",
            system: prompts.system,
            messages: [
              {
                role: "user",
                content: [
                  {
                    type: "text",
                    text: promptMapper[type]
                  }
                ]
              }
            ],
            max_tokens: 1000,
            temperature: 0.7,
          })
        };

        const command = new InvokeModelWithResponseStreamCommand(input);
        const response = await client.send(command);

        if (!response.body) {
          throw new Error('No response body received');
        }

        // Process the streaming response
        const decoder = new TextDecoder();
        for await (const chunk of response.body) {
          if ('chunk' in chunk) {
            const decodedChunk = decoder.decode(chunk?.chunk?.bytes);
            console.log('Decoded chunk:', decodedChunk);
            const jsonChunk = JSON.parse(decodedChunk);
            const textChunk = jsonChunk.delta?.text || '';
            console.log('Text chunk:', textChunk);
            setRecommendation(prev => prev + textChunk);
          } else if ('error' in chunk) {
            console.error('Error in stream:', chunk.error);
            throw new Error('Unknown error in stream');
          }
        }
      } catch (error) {
        console.error('Error generating recommendation:', error);
        setRecommendation('Unable to generate recommendations at this time. Please try again later.');
      }
    };

    generateRecommendation();
  }, [type]);

  const header = useMemo(() => {
    const title = titleMapper[type];
    const date = getDate(new Date(pointDate));

    return (
      <div className={`modal-header modal-header_type-${type}`}>
        <CriticalIcon className="modal-icon" />
        <span className="modal-title">{title}</span>
        <div className="modal-date">{date.h}:{date.min}, {date.mon} {date.d}, {date.y}</div>
        <button onClick={close} className="modal-close"></button>
      </div>
    );
  }, [type]);

  return (
    <div>
      {header}
      <div className="modal-body">
        <div className="modal-notification">
          I've identified a
          <span className={`modal-notification__sign modal-notification__sign_${type}`}>
            <FloodHouse className="modal-notification__sign-icon" />
            {text}
          </span>
          at your premises at <b>Hilton Residential, New York, Floor 2, Unit B32.</b>
        </div>
        <div className="modal-separator"></div>
        <div className="modal-notification"><b>For the safety of your property, I've decided to close the valve.</b></div>
        <div className="modal-recommendations">
          <div className="modal-recommendations__inner">
            <div className="modal-recommendations__title">Our recommendation</div>
            <div className="modal-recommendations__content">
              <div className="markdown-content typing-effect">

                <ReactMarkdown>
                  {recommendation}
                </ReactMarkdown>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
};

export default Modal;