import React, { useEffect, useMemo, useState, useRef, useCallback } from 'react';
import charts from 'config/charts';
import IframeChart from 'components/IframeChart';
import ScenarioButton from 'components/ScenarioButton';
import Modal from 'components/Modal';
import { ModalOptions, TypeScenario, Scenario } from 'types/common';
import { ScenarioNumber } from 'constants/index';
import PreloadedIframeChart from 'components/PreloadedIframeChart';

// Custom hook for managing scenarios
const useScenario = () => {
  const [scenario, setScenario] = useState<Scenario | undefined>();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const abortControllerRef = useRef<AbortController | null>(null);
  const timerRef = useRef<NodeJS.Timeout>();
  const isMountedRef = useRef(true);

  useEffect(() => {
    return () => {
      isMountedRef.current = false;
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);

  const fetchWithAbort = async (url: string, body: any) => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    abortControllerRef.current = new AbortController();

    try {
      const response = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(body),
        signal: abortControllerRef.current.signal,
      });

      if (!response.ok) {
        throw new Error('Network response was not ok ' + response.statusText);
      }

      return await response.json();
    } catch (error) {
      if ((error as Error).name === 'AbortError') {
        console.log('Request was aborted');
        return null;
      }
      throw error;
    }
  };

  const initScenario = useCallback(async (type: TypeScenario) => {
    setIsButtonDisabled(true);

    const initScenario: Partial<Scenario> = {
      type,
      pointNumber: 0,
      isAlarm: false,
      isRunning: false,
      isInit: false,
      number: type === 'critical' ? ScenarioNumber.CRITICAL :
        type === 'warn' ? ScenarioNumber.WARN :
          type === 'info' ? ScenarioNumber.INFO : undefined
    };

    if (!initScenario.number) {
      console.error('Invalid scenario type');
      return;
    }

    setScenario(initScenario as Scenario);

    try {
      const response = await fetchWithAbort(
        'https://v4ydlys6z7hprnh7ghsssfhkjq0orcet.lambda-url.us-east-1.on.aws',
        { scenario: initScenario.number }
      );

      if (response && isMountedRef.current) {
        setScenario(prev => prev ? { ...prev, isInit: true, isRunning: true } : undefined);
      }
    } catch (error) {
      console.error('Scenario Initialization ERROR:', error);
      if (isMountedRef.current) {
        setIsButtonDisabled(false);
      }
    }
  }, []);

  const addScenarioPoint = useCallback(async () => {
    if (!scenario?.isRunning) return;

    const currentPointNum = scenario.pointNumber;

    try {
      // Set up the timer
      const timer = new Promise(resolve => {
        timerRef.current = setTimeout(resolve, 1000);
      });

      const [response] = await Promise.all([
        fetchWithAbort(
          'https://jx374djccpocbfmgdfip756p7q0yxbqy.lambda-url.us-east-1.on.aws',
          { scenario: scenario.number, point: currentPointNum + 1 }
        ),
        timer
      ]);

      if (!response || !isMountedRef.current) return;

      if (response.isAlarm) {
        setIsButtonDisabled(false);
        setScenario(prev => prev ? {
          ...prev,
          isAlarm: true,
          pointDate: response.pointDate,
          pointNumber: currentPointNum + 1
        } : undefined);
        return response;
      }

      setScenario(prev => prev ? {
        ...prev,
        pointNumber: currentPointNum + 1
      } : undefined);

    } catch (error) {
      console.error('Add point ERROR:', error);
      if (isMountedRef.current) {
        setScenario(prev => prev ? { ...prev, isRunning: false } : undefined);
      }
    }
  }, [scenario]);

  const resetScenario = useCallback(() => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    setScenario(undefined);
    setIsButtonDisabled(false);
  }, []);

  return {
    scenario,
    isButtonDisabled,
    initScenario,
    addScenarioPoint,
    resetScenario
  };
};

// Custom hook for managing modal state
const useModal = () => {
  const [isShowModal, setIsShowModal] = useState(false);
  const [modalOptions, setModalOptions] = useState<ModalOptions | null>(null);

  const showModal = useCallback((response: any, scenario: Scenario) => {
    const options: ModalOptions = {
      type: scenario.type,
      pointDate: scenario.pointDate,
      close: closeModal
    };
    setModalOptions(options);
    setIsShowModal(true);
  }, []);

  const closeModal = useCallback(() => {
    setIsShowModal(false);
    setTimeout(() => setModalOptions(null), 20);
  }, []);

  return {
    isShowModal,
    modalOptions,
    showModal,
    closeModal
  };
};

const Main = () => {
  const {
    scenario,
    isButtonDisabled,
    initScenario,
    addScenarioPoint,
    resetScenario
  } = useScenario();

  const {
    isShowModal,
    modalOptions,
    showModal,
    closeModal
  } = useModal();

  const chartsArr = useMemo(() => {
    return Object.keys(charts).map(name => {
      const chart = { ...charts[name] };

      // Add scenario sources where needed
      switch (name) {
        case 'chart2':
          chart.scenarioSrc = 'https://garnet.rd.klika-tech.com/grafana/d-solo/ads43bahcqc5ce/garnet?orgId=1&from=now-24h&to=now&refresh=1s&theme=light&panelId=8';
          break;
        case 'chart3':
          chart.scenarioSrc = 'https://garnet.rd.klika-tech.com/grafana/d-solo/ads43bahcqc5ce/garnet?orgId=1&from=now-24h&to=now&refresh=1s&theme=light&panelId=9';
          break;
        case 'chart4':
          chart.scenarioSrc = 'https://garnet.rd.klika-tech.com/grafana/d-solo/ads43bahcqc5ce/garnet?orgId=1&from=now-24h&to=now&refresh=1s&theme=light&panelId=10';
          break;
      }

      return chart;
    });
  }, []); // Empty dependency array since we don't need to react to scenario changes anymore

  useEffect(() => {
    let mounted = true;

    const runScenarioPoint = async () => {
      const response = await addScenarioPoint();
      if (response?.isAlarm && mounted) {
        showModal(response, { ...scenario!, pointDate: response.pointDate });
      }
    };

    if (scenario?.isRunning) {
      runScenarioPoint();
    }

    return () => {
      mounted = false;
    };
  }, [scenario?.isRunning, scenario?.number, scenario?.pointNumber, addScenarioPoint, showModal]);

  const handleInitScenario = (type: TypeScenario) => {
    if (isShowModal) {
      closeModal();
    }
    initScenario(type);
  };

  const handleResetScenario = () => {
    resetScenario();
    closeModal();
  };

  return (
    <div className="main-wrapper">
      <main className="main">
        {chartsArr.map(chart => (
          <div className="main__item" key={chart.src}>
            <div className="main__item_inner">
              <PreloadedIframeChart chart={chart} extra={scenario} />
            </div>
          </div>
        ))}

        <div className="main__scenario-buttons">
          <ScenarioButton
            type="critical"
            onClick={handleInitScenario}
            disabled={isButtonDisabled}
            isRunning={scenario?.isInit && scenario?.isRunning && scenario?.number === ScenarioNumber.CRITICAL}
          />
          <ScenarioButton
            type="warn"
            onClick={handleInitScenario}
            disabled={isButtonDisabled}
            isRunning={scenario?.isInit && scenario?.isRunning && scenario?.number === ScenarioNumber.WARN}
          />
          <ScenarioButton
            type="info"
            onClick={handleInitScenario}
            disabled={isButtonDisabled}
            isRunning={scenario?.isInit && scenario?.isRunning && scenario?.number === ScenarioNumber.INFO}
          />
        </div>

        <div className="reset-button-container">
          <button className="reset-button" title="Reset" onClick={handleResetScenario}></button>
        </div>

        <div className={`main__modal-container ${isShowModal ? 'show' : ''}`}>
          <div className="main__modal-wrapper">
            {modalOptions ? <Modal {...modalOptions} /> : null}
          </div>
        </div>
      </main>
    </div>
  );
};

export default Main;