import { DateCustom } from 'types/date';

const getMonthShort = (date: Date): string | undefined => {
  if (!date.toDateString) return;

  const strDate = date.toDateString();
  return strDate.split(' ')[1];
}

export const getDate = (date: Date): DateCustom => {
  const dateNum = date.getDate();
  const dateMonth = getMonthShort(date);
  const dateYear = date.getFullYear();
  const hours = ('0' + date.getHours()).slice(-2);
  const minutes = ('0' + date.getMinutes()).slice(-2);

  return {
    y: dateYear,
    mon: dateMonth || '',
    d: dateNum,
    h: hours,
    min: minutes,
    origDate: date,
  };
}

export const delay = (ms:number) => <T>(x?: T) => {
  return new Promise(resolve => setTimeout(() => resolve(x), ms));
};
