import { createContext, useContext, useState, ReactNode } from 'react';
import { BedrockRuntimeClient } from '@aws-sdk/client-bedrock-runtime';
import { fetchAuthSession } from 'aws-amplify/auth';
import awsExports from 'config/aws-exports';
// Context and Provider
type BedrockContextType = {
    getClient: () => Promise<BedrockRuntimeClient>;
};

const BedrockContext = createContext<BedrockContextType | null>(null);

export const useBedrock = () => {
    const context = useContext(BedrockContext);
    if (!context) {
        throw new Error('useBedrock must be used within a BedrockProvider');
    }
    return context;
};

// Provider that initializes the client lazily
export const BedrockProvider = ({ children }: { children: ReactNode }) => {
    const [initPromise, setInitPromise] = useState<Promise<BedrockRuntimeClient> | null>(null);

    const getClient = async () => {
        if (!initPromise) {
            const promise = (async () => {
                console.log('🚀 Initializing Bedrock client...');
                const { credentials } = await fetchAuthSession();
                console.log('✅ Got credentials from Amplify session');

                return new BedrockRuntimeClient({
                    region: awsExports.Bedrock.region, // replace with your region
                    credentials
                });
            })();

            setInitPromise(promise);
            return promise;
        }

        return initPromise;
    };

    return (
        <BedrockContext.Provider value={{ getClient }}>
            {children}
        </BedrockContext.Provider>
    );
};

export default BedrockProvider;
