import React, { ReactElement, useCallback, useMemo, useState, useEffect } from 'react';
import { Chart } from 'types/Chart';
import DatePicker from 'components/Datepicker';

interface Props {
    chart: Chart;
    extra: any;
}

interface IframeState {
    src: string;
    isLoaded: boolean;
    isVisible: boolean;
}

interface IframesState {
    default: IframeState;
    scenario?: IframeState;
}

const PreloadedIframeChart = ({ chart, extra }: Props) => {
    const [startDate, setStartDate] = useState(new Date());
    const [iframes, setIframes] = useState<IframesState>({
        default: {
            src: chart.src,
            isLoaded: false,
            isVisible: true
        },
        ...(chart.scenarioSrc ? {
            scenario: {
                src: chart.scenarioSrc,
                isLoaded: false,
                isVisible: false
            }
        } : {})
    });

    useEffect(() => {
        if (!chart.scenarioSrc) return;

        const shouldShowScenario = Boolean(extra?.isInit);

        if (shouldShowScenario) {
            // Only add delay when switching TO scenario
            setTimeout(() => {
                setIframes(prev => {
                    if (!prev.scenario) return prev;

                    return {
                        default: {
                            ...prev.default,
                            isVisible: false
                        },
                        scenario: {
                            ...prev.scenario,
                            isVisible: true
                        }
                    };
                });
            }, 1200); // 1 second delay
        } else {
            // Immediate switch back to default
            setIframes(prev => {
                if (!prev.scenario) return prev;

                return {
                    default: {
                        ...prev.default,
                        isVisible: true
                    },
                    scenario: {
                        ...prev.scenario,
                        isVisible: false
                    }
                };
            });
        }
    }, [extra?.isInit, chart.scenarioSrc]);

    const handleDateChange = useCallback((date: Date) => {
        const currentDate = new Date();
        const newDate = new Date(date);

        if (!chart.timeTo) {
            newDate.setHours(currentDate.getHours());
            newDate.setMinutes(currentDate.getMinutes());
        } else {
            newDate.setHours(chart.timeTo.h);
            newDate.setMinutes(chart.timeTo.m);
            newDate.setSeconds(chart.timeTo.s);
        }

        const updateSrc = (originalSrc: string) =>
            originalSrc
                .replace(/(from=)[^&]+/, `$1${newDate.valueOf() - chart.timeRange}`)
                .replace(/(to=)[^&]+/, `$1${newDate.valueOf()}`);

        setIframes(prev => {
            const newState: IframesState = {
                default: {
                    ...prev.default,
                    src: updateSrc(chart.src),
                    isLoaded: false
                }
            };

            if (chart.scenarioSrc && prev.scenario) {
                newState.scenario = {
                    ...prev.scenario,
                    src: updateSrc(chart.scenarioSrc),
                    isLoaded: false
                };
            }

            return newState;
        });

        setStartDate(newDate);
    }, [chart]);

    const handleIframeLoad = useCallback((type: keyof IframesState) => {
        setIframes(prev => ({
            ...prev,
            [type]: { ...prev[type]!, isLoaded: true }
        }));
    }, []);

    const Addon: ReactElement | null = useMemo(() => {
        if (!chart.addon?.componentGetter) return null;

        const componentGetter = chart.addon.componentGetter;
        const addonProps = chart.addon.props || {};
        const extraProps = extra || {};
        const isLoaded = iframes.scenario?.isVisible
            ? iframes.scenario.isLoaded
            : iframes.default.isLoaded;

        return componentGetter({
            ...addonProps,
            date: startDate,
            chart,
            isMasterLoaded: isLoaded,
            ...extraProps
        });
    }, [chart, startDate, iframes, extra]);

    return (
        <div className="iframe-container">
            <div className="iframe-container__header">
                <h2 className="iframe-container__title">{chart.title}</h2>
                <div>
                    <DatePicker handleChange={handleDateChange} date={startDate} />
                </div>
            </div>
            <div className="iframe-container__content iframe-wrapper">
                <div className="iframe-wrapper__inner chart-wrapper__inner">
                    <div className="edge-cut">
                        <div className="iframe-container__frames chart-frames">
                            {/* Default iframe */}
                            <div className="chart-frame" style={{
                                opacity: iframes.default.isVisible ? 1 : 0,
                                pointerEvents: iframes.default.isVisible ? 'auto' : 'none',
                                zIndex: iframes.default.isVisible ? 2 : 1,
                                transition: 'opacity 0.3s ease-in-out'
                            }}>
                                <iframe className="chart-frame"
                                    src={iframes.default.src}
                                    title={`${chart.title} - Default`}
                                    onLoad={() => handleIframeLoad('default')}
                                />
                            </div>

                            {/* Scenario iframe */}
                            {iframes.scenario && (
                                <div className="chart-frame" style={{
                                    opacity: iframes.scenario.isVisible ? 1 : 0,
                                    pointerEvents: iframes.scenario.isVisible ? 'auto' : 'none',
                                    zIndex: iframes.scenario.isVisible ? 2 : 1,
                                    transition: 'opacity 0.3s ease-in-out'
                                }}>
                                    <iframe className="chart-frame"
                                        src={iframes.scenario.src}
                                        title={`${chart.title} - Scenario`}
                                        onLoad={() => handleIframeLoad('scenario')}
                                    />
                                </div>
                            )}

                            {/* Centered Addon */}
                            {Addon && (
                                <div className="chart-addon">
                                    <div className="chart-addon__content">
                                        {Addon}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PreloadedIframeChart;